<template>
    <div id="all-padroesEventos" class="animated fadeIn">
        <div class="row"><div class="col-md-12">
          <b-card>
            <div slot="header">
            <strong>Lista de usuários do sistema</strong>
          </div>
        <p v-if="perm.isAdmin"><router-link :to="{ name: 'Criar Usuário', params: { id: this.$route.params.id } }" class="btn btn-primary">Criar usuário</router-link></p>

        <div class="form-group">
            <input type="text" name="search" v-model="usuarioSearch" placeholder="Procurar por nome do usuário" class="form-control" v-on:keyup="searchUsuario">
        </div>

        <table class="table table-hover">
            <thead>
            <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Telefone</th>
                <th>Empresa</th>
                <th>Grupo</th>
                <th>Ações</th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="usuarioItem in usuariosItens" :key="usuarioItem.id">
                    <td>{{ usuarioItem.name }}</td>
                    <td>{{ usuarioItem.email }}</td>
                    <td>{{ usuarioItem.phone }}</td>
                    <td>{{ usuarioItem.grp_mnemonico }}</td>
                    <td>{{ osGrupos[usuarioItem.usr_ID] }}</td>
                    <td v-if="usuarioItem.is_active">
                        <div v-if="perm.isAdmin">
                        <router-link :to="{name: 'Editar Usuário', params: { id: usuarioItem.id }}" class="btn btn-success">Editar</router-link>
                        <button type="button" v-on:click="desativaUser(usuarioItem.id)" class="btn btn-danger">Desativar</button>
                        </div>
                    </td>
                    <td v-else>
                      <div v-if="perm.isAdmin">
                        <button type="button" v-on:click="ativaUser(usuarioItem.id)" class="btn btn-warning">Ativar</button>
                      </div>
                    </td>
                </tr>
            </tbody>
        </table>
          </b-card>
        </div></div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      usuarios: [],
      usuariosItens: [],
      originalUsuariosItens: [],
      usuarioSearch: '',
      sessao: '',
      osPerfis: [],
      osGrupos: [],
      perm: []
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('ADMINISTRATIVO'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.permissao_area().then(
          (response) => {
            this.perm = response
            this.listar('filtro_User_Grupo', 'name', 'gtipo_ID = 1').then(
              (response) => {
                for (var i = 0; i < response.length; i++) {
                  this.osGrupos[response[i].usr_ID] = response[i].grp_mnemonico
                }
                this.listar('filtro_User_Grupo', 'name', 'gtipo_ID = 2').then(
                  (r) => {
                    this.usuariosItens = r
                    this.originalUsuariosItens = this.usuariosItens
                  },
                  (r) => {}
                )
              }, (response) => {}
            )
          },
          (response) => {
            this.perm = response
          }
        )
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    ativaUser: function (idu) {
      this.atualizar('user', {is_active: 1}, idu, 'system').then(
        (response) => { document.location.reload() },
        (response) => {}
      )
    },
    desativaUser: function (idu) {
      this.atualizar('user', {is_active: 0}, idu, 'system').then(
        (response) => { document.location.reload() },
        (response) => {}
      )
    },
    searchUsuario: function () {
      if (this.usuarioSearch === '') {
        this.usuariosItens = this.originalUsuariosItens
        return
      }
      var searchedUsuariosItens = []
      for (var i = 0; i < this.originalUsuariosItens.length; i++) {
        var usuariosName = this.originalUsuariosItens[i]['name'].toLowerCase()
        if (usuariosName.indexOf(this.usuarioSearch.toLowerCase()) >= 0) {
          searchedUsuariosItens.push(this.originalUsuariosItens[i])
        }
      }
      this.usuariosItens = searchedUsuariosItens
    }
  }
}
</script>